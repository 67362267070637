import { render, staticRenderFns } from "./ModeloVeiculo.vue?vue&type=template&id=1bc98a71&scoped=true&"
import script from "./ModeloVeiculo.vue?vue&type=script&lang=js&"
export * from "./ModeloVeiculo.vue?vue&type=script&lang=js&"
import style0 from "./ModeloVeiculo.vue?vue&type=style&index=0&id=1bc98a71&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bc98a71",
  null
  
)

export default component.exports